import {fetchProjectReportData, fetchTranslatorStatisticReportData} from "../../../api/ReportDataApi";
import {dispatcher} from "../../Dispatcher";
import {cleanupReportData, setReportData, setReportDataIsLoading} from "./ReportDataPayload";
import {Report, ReportType} from "../../../model/Report";
import {Page} from "../../../model/Page";
import {ReportDataType} from "../../../model/ReportDataType";

export async function fetchReportDataAction(report: Report) {
    dispatcher.dispatch(setReportDataIsLoading(true));

    let data: Page<ReportDataType>;
    switch (report.variety) {
        case ReportType.Project:
            data = await fetchProjectReportData(report);
            break;
        case ReportType.TranslatorStatistic:
            data = await fetchTranslatorStatisticReportData(report);
            break;
        default:
            return;
    }

    dispatcher.dispatch(setReportData(report, data));
    dispatcher.dispatch(setReportDataIsLoading(false));
}

export function cleanupReportDataAction() {
    dispatcher.dispatch(cleanupReportData());
}