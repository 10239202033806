import {
    Button,
    FormControl,
    Grid2,
    Icon,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    pointToSegmentAction,
    replaceTranslationsAction,
    toggleSelectAllAction
} from "../../../../flux/segment/list/SegmentListActions";
import {SelectionModel} from "../../../../model/SelectionModel";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import SearchIcon from "@mui/icons-material/Search";
import MatchCaseAdornment from "./MatchCaseAdornment";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import {List} from "immutable";
import Project from "../../../../model/Project";
import {grey} from "@mui/material/colors";
import {handleWorkflowFilterChanged} from "../../../../utils/FilterUtils";
import {replaceTranslationAction} from "../../../../flux/segment/editor/SegmentEditorActions";

export default function SegmentListControls() {
    const projectState = projectPageStore.getState();

    const [selection, setSelection] = useState(new SelectionModel());
    const [totalItems, setTotalItems] = useState(0);
    const [source, setSource] = useState("");
    const [target, setTarget] = useState("");
    const initialSegmentListFilterState = segmentListStore.getState();
    const [sourceMatchCase, setSourceMatchCase] = useState(false);
    const [targetMatchCase, setTargetMatchCase] = useState(false);
    const [selectedWorkflowSteps, setSelectedWorkflowSteps] = useState<List<number>>(List());
    const [currentProject, setCurrentProject] = useState<Project>(projectState.project);
    const [filter, setFilter] = useState(initialSegmentListFilterState.filter);
    const [position, setPosition] = useState(initialSegmentListFilterState.position);
    const [replaceWithChecked, setReplaceWithChecked] = useState(false);
    const [replacement, setReplacement] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
        });

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const workflowIds = state.filter.workflowIds;
            setSelection(state.selection);
            setTotalItems(state.totalsItems);
            setFilter(state.filter);
            if (!state.filter.target)
                setReplaceWithChecked(false);
            setPosition(state.position);
            setSelectedWorkflowSteps(workflowIds ? List(workflowIds) : List());
        });

        return () => {
            segmentListListener.remove();
            projectPageListener.remove();
        };
    });

    return (
        <Grid2 container rowSpacing={0.5} columnSpacing={1} alignItems={"center"} sx={{padding: '0px'}}>
            <Grid2 size={1}>
                {drawControls(selection, totalItems)}
            </Grid2>
            <Grid2 size={4}>
                <TextField
                    size="small"
                    fullWidth
                    placeholder="Search in Source"
                    onChange={event => setSource(event.target.value)}
                    value={source}
                    onBlur={() => handleSaveFilter(
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    onKeyDown={e => handelOnCompleteEdit(
                        e,
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    slotProps={{
                        input: {
                            style: {height: '30px'},
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{color: grey[500]}}/>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <MatchCaseAdornment matchCase={sourceMatchCase} setMatchCase={setSourceMatchCase}/>
                            )
                        }
                    }}/>
            </Grid2>
            <Grid2 size={4}>
                <TextField
                    size="small"
                    fullWidth
                    placeholder="Search in Target"
                    onChange={event => setTarget(event.target.value)}
                    value={target}
                    onBlur={() => handleSaveFilter(
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    onKeyDown={e => handelOnCompleteEdit(
                        e,
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{color: grey[500]}}/>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <MatchCaseAdornment matchCase={targetMatchCase} setMatchCase={setTargetMatchCase}/>
                            ),
                            style: {height: '30px'}
                        }
                    }}/>
            </Grid2>
            {filter.target && <Grid2 size={1}>
                <Tooltip title={"Replace with"}>
                    <Checkbox
                        checked={replaceWithChecked}
                        onChange={e => setReplaceWithChecked(e.target.checked)}
                        icon={<Icon>
                            <img src={"/find_replace.svg"} alt={"find_replace"}/>
                        </Icon>}
                        checkedIcon={<Icon>
                            <img src={"/find_replace_checked.svg"} alt={"find_replace_checked"}/>
                        </Icon>}/>
                </Tooltip>
            </Grid2>}
            <Grid2 size={"grow"}>
                <FormControl variant="outlined" style={{minWidth: '150px', paddingRight: '10px'}} fullWidth>
                    <InputLabel shrink>
                        Status
                    </InputLabel>
                    <Select sx={{height: '30px', lineHeight: '30px'}}
                            label="Status"
                            displayEmpty
                            multiple value={selectedWorkflowSteps.toArray()}
                            onChange={e => handleWorkflowFilterChanged(e, filter, navigate)}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return "All Statuses";
                                }
                                return selected.map(id => {
                                    if (!currentProject)
                                        return;
                                    const found = currentProject.workflow
                                        .find(step => step.id === Number(id));
                                    return !found ? id : found.name;
                                }).join(', ')
                            }} variant={'outlined'}>
                        {currentProject && currentProject.workflow.map(workflowStep =>
                            <MenuItem key={workflowStep.id} value={workflowStep.id}>
                                <Checkbox checked={
                                    !workflowStep.id
                                        ? false
                                        : selectedWorkflowSteps.indexOf(workflowStep.id) > -1
                                }/>
                                <ListItemText>{workflowStep.name}</ListItemText>
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Grid2>
            {replaceWithChecked && <Grid2 size={5}/>}
            {replaceWithChecked && <Grid2 size={4}>
                <TextField size={"small"} slotProps={{input: {style: {height: '30px'}}}} fullWidth
                           placeholder={"Replace with"} value={replacement}
                           onChange={e => setReplacement(e.target.value)}/>
            </Grid2>}
            {replaceWithChecked && <Grid2 size={3}>
                <Button onClick={() => handleReplace(filter, position, replacement)}>Replace</Button>
                <Button onClick={() => handleReplaceAll(navigate, filter, replacement)}>Replace all</Button>
            </Grid2>}
        </Grid2>
    );
}

function drawControls(selection: SelectionModel, totalItems: number) {
    return (
        <Box display="flex" alignItems="center" sx={{paddingRight: '30px'}}>
            {drawCheckbox(selection)}
            {selection.countSelected(totalItems)}
        </Box>
    );
}

function drawCheckbox(selection: SelectionModel) {
    let isIndeterminate = selection.invertedSelection.size > 0;
    let isCheckedAll = selection.isSelectAll;

    return <Checkbox sx={{paddingLeft: 0, paddingRight: '15px'}}
                     onChange={_ignored => toggleSelectAllAction()}
                     indeterminate={isIndeterminate}
                     checked={isCheckedAll}/>;
}

function handleSaveFilter(filter: SegmentListFilter,
                          source: string,
                          target: string,
                          sourceMatchCase: boolean,
                          targetMatchCase: boolean,
                          setSource: (s: string) => void,
                          setTarget: (s: string) => void,
                          setSourceMatchCase: (v: boolean) => void,
                          setTargetMatchCase: (v: boolean) => void,
                          navigate: NavigateFunction) {
    let updatedFilter = filter.set("sourceMatchCase", sourceMatchCase).set("targetMatchCase", targetMatchCase);
    if (source)
        updatedFilter = updatedFilter.set("source", source);

    if (target)
        updatedFilter = updatedFilter.set("target", target);

    const currentSegment = segmentEditorStore.getState().segment;
    if (!currentSegment)
        return;
    navigate(getSegmentPath(updatedFilter, currentSegment.order), {replace: true});

    setSource("");
    setTarget("");
    setSourceMatchCase(false);
    setTargetMatchCase(false);
}

function handelOnCompleteEdit(event: React.KeyboardEvent<HTMLDivElement>,
                              filter: SegmentListFilter,
                              source: string,
                              target: string,
                              sourceMatchCase: boolean,
                              targetMatchCase: boolean,
                              setSource: (s: string) => void,
                              setTarget: (s: string) => void,
                              setSourceMatchCase: (v: boolean) => void,
                              setTargetMatchCase: (v: boolean) => void,
                              navigate: NavigateFunction) {
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            handleSaveFilter(
                filter,
                source,
                target,
                sourceMatchCase,
                targetMatchCase,
                setSource,
                setTarget,
                setSourceMatchCase,
                setTargetMatchCase,
                navigate);
        }
    }
}

function handleReplace(filter: SegmentListFilter, position: number, replacement: string) {
    if (!filter.target)
        return;
    replaceTranslationAction(filter.target, replacement);
    pointToSegmentAction(position + 1);
}

async function handleReplaceAll(navigate: NavigateFunction, filter: SegmentListFilter, replacement: string) {
    if (!filter.target)
        return;
    await replaceTranslationsAction(filter.target, replacement);
    const updatedFilter = filter.set("target", "");
    const currentSegment = segmentEditorStore.getState().segment;
    if (!currentSegment)
        return;
    navigate(getSegmentPath(updatedFilter, currentSegment.order), {replace: true});
}