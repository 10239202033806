import {Permission} from "../../../model/Permission";
import {PermissionEntityPart, PermissionEntityPartType} from "../../../model/PermissionEntityPart";
import projectPageStore from "../../project/page/ProjectPageStore";
import {List} from "immutable";
import CatFilesFilter from "../../cat-files/CatFilesFilter";
import {permissionsListActions} from "../list/PermissionsSelectActions";
import {userListActions} from "../../users/select/UserListActions";
import {workflowSelectActions} from "../../workflow/select/WorkflowSelectActions";
import {projectLanguagesSelectActions} from "../../language/select/ProjectLanguagesSelectActions";
import {catFileSelectActions} from "../../cat-files/select/CatFileSelectActions";
import NullFilter from "../../common/NullFilter";
import UsersListFilter from "../../users/UsersListFilter";
import ProjectLanguagesFilter from "../../language/select/ProjectLanguagesFilter";
import {NavigateFunction} from "react-router-dom";
import {PermissionsUsersPath} from "../../../routes/project/tabs/permissions/PermissionsUsersRoute";
import {PermissionsFilesPath} from "../../../routes/project/tabs/permissions/PermissionsFilesRoute";
import {PermissionsWorkflowsPath} from "../../../routes/project/tabs/permissions/PermissionsWorkflowsRoute";
import {PermissionsLanguagesPath} from "../../../routes/project/tabs/permissions/PermissionsLanguagesRoute";

interface IPermissionEntity {
    projectId: number | null,
    catFileId: number | null,
    language: string | null,
    workflow: number | null
}

export function initPermissionsEditor(projectId: number, navigate: NavigateFunction, tab: number) {
    catFileSelectActions.fetch(new CatFilesFilter({projectId: projectId}));
    userListActions.fetch(new UsersListFilter());
    workflowSelectActions.fetch(new NullFilter());
    projectLanguagesSelectActions.fetch(new ProjectLanguagesFilter());
    let path = "/";
    switch(tab)
    {
        case 0:
            path = PermissionsUsersPath;
            break;
        case 1:
            path = PermissionsFilesPath;
            break;
        case 2:
            path = PermissionsWorkflowsPath;
            break;
        case 3:
            path = PermissionsLanguagesPath;
            break;
    }
    navigate(path);
}

export async function saveUserPermissionsAction() {
    permissionsListActions.setIsLoading()

    const projects = List.of(projectPageStore.getState().project);
    const catFiles = catFileSelectActions.state.selected;
    const languages = projectLanguagesSelectActions.state.selected;
    const workflows = workflowSelectActions.state.selected;
    const users = userListActions.state.selected;
    if (users.size === 0)
        return 0;

    let permissionEntities = List.of({
        catFileId: null,
        projectId: null,
        language: null,
        workflow: null
    } as IPermissionEntity);

    if (!catFiles.isEmpty()) {
        permissionEntities = permissionEntities
            .flatMap(entity => catFiles.map(catFile => {
                let created = structuredClone(entity);
                created.catFileId = catFile.id;
                return created;
            }))
    }

    if (!workflows.isEmpty()) {
        permissionEntities = permissionEntities
            .flatMap(entity => workflows.map(workflow => {
                let created = structuredClone(entity);
                created.workflow = workflow.id
                return created;
            }))
    }

    if (!projects.isEmpty()) {
        permissionEntities = permissionEntities
            .flatMap(entity => projects.map(project => {
                let created = structuredClone(entity);
                created.projectId = project.id;
                return created;
            }))
    }

    if (!languages.isEmpty()) {
        permissionEntities = permissionEntities
            .flatMap(entity => languages.map(language => {
                let created = structuredClone(entity);
                created.language = language.id
                return created;
            }))
    }

    const permissions = permissionEntities
        .flatMap(entity => users.toList().map(user => {
            const permissionEntity = permissionEntityAsList(entity);
            if (permissionEntity.isEmpty())
                return null;
            return new Permission({
                userId: user.id,
                permissionEntity: permissionEntity,
                readAllowed: true
            });
        }))
        .filter(value => value != null)
        .map(value => value as Permission);

    await permissionsListActions.saveAll(permissions);
}

function permissionEntityAsList(entity: IPermissionEntity) {
    let entityPartList = List.of<PermissionEntityPart>();
    if (entity.projectId) {
        entityPartList = entityPartList.push(new PermissionEntityPart({
            id: entity.projectId.toString(),
            type: PermissionEntityPartType.Project
        }));
    }

    if (entity.catFileId) {
        entityPartList = entityPartList.push(new PermissionEntityPart({
            id: entity.catFileId.toString(),
            type: PermissionEntityPartType.CatFile
        }));
    }

    if (entity.workflow) {
        entityPartList = entityPartList.push(new PermissionEntityPart({
            id: entity.workflow.toString(),
            type: PermissionEntityPartType.WorkflowStep
        }));
    }

    if (entity.language) {
        entityPartList = entityPartList.push(new PermissionEntityPart({
            id: entity.language,
            type: PermissionEntityPartType.Language
        }));
    }

    return entityPartList;
}