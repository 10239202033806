import {emptyAsNull} from "../../globals/ApiGlobals";
import {YandexAccount} from "../../model/YandexAccount";

export function toYandexAccountModel(response: IYandexAccountResponse | null) {
    if (response == null)
        return new YandexAccount();

    return new YandexAccount({
        id: response.id,
        name: response.name,
    });
}

export function toYandexAccountRequest(model: YandexAccount) {
    return {
        id: emptyAsNull(model.id),
        name: model.name,
        iamToken: model.iamToken
    } as IYandexAccountRequest;
}

export interface IYandexAccountRequest {
    id: number | null,
    name: string
    iamToken: string,
}

export interface IYandexAccountResponse {
    id: number,
    name: string,
}