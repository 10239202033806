import TranslationMemory from "../../../model/TranslationMemory";
import {dispatcher} from "../../Dispatcher";
import {setEditedTranslationMemory, setExistedTranslationMemory} from "./TranslationMemoryEditorPayload";
import {
    createTranslationMemory,
    fetchTranslationMemoryByName,
    uploadTranslationMemory
} from "../../../api/TranslationMemoryApi";
import {translationMemoryListActions} from "../list/TranslationMemoryListActions";
import {translationMemorySelectActions} from "../select/TranslationMemorySelectActions";

export async function uploadTranslationMemoryAction(tm: TranslationMemory, tmFile: File) {
    translationMemoryListActions.setIsLoading();
    const translationMemory = await uploadTranslationMemory(tm, tmFile);
    await translationMemoryListActions.refresh();
    await translationMemorySelectActions.refresh();
    return translationMemory;
}

export async function createTranslationMemoryAction(tm: TranslationMemory) {
    translationMemoryListActions.setIsLoading();
    await createTranslationMemory(tm);
    await translationMemoryListActions.refresh();
    await translationMemorySelectActions.refresh();
}

export async function setEditedTranslationMemoryAction(tm: TranslationMemory) {
    dispatcher.dispatch(setEditedTranslationMemory(tm));
    await checkTmNameIsExisted(tm.name);
}

async function checkTmNameIsExisted(name: string) {
    const existed = await fetchTranslationMemoryByName(name);
    dispatcher.dispatch(setExistedTranslationMemory(existed));
}