import {Report, ReportState, ReportType} from "../../../model/Report";
import {
    fetchProjectReport,
    fetchTranslatorStatisticReport,
    generateProjectReport,
    generateTranslatorStatisticReport
} from "../../../api/ReportApi";
import {ProjectReportFilter, ReportFilters, TranslatorStatisticReportFilter} from "../../../model/ReportFilters";
import reportListStore from "./ReportListStore";
import {Page} from "../../../model/Page";
import {dispatcher} from "../../Dispatcher";
import {
    generateReport,
    setReportList,
    setReportListFilter,
    setReportListIsLoading,
    setReportListPage
} from "./ReportListPayload";
import {List} from "immutable";
import {cleanupReportDataAction} from "../data/ReportDataActions";
import projectPageStore from "../../project/page/ProjectPageStore";
import {catFileSelectActions} from "../../cat-files/select/CatFileSelectActions";

export async function setReportListVarietyAction(variety: ReportType) {
    const current = reportListStore.getState().filter.variety;
    if (current === variety)
        return;

    const projectId = projectPageStore.getState().project.id;

    let filter = null;
    switch (variety) {
        case ReportType.Project:
            filter = ProjectReportFilter.ofProject(projectId);
            break;
        case ReportType.TranslatorStatistic:
            filter = TranslatorStatisticReportFilter.ofProject(projectId);
            break;
        default:
            return
    }

    await setReportListFilterAction(filter);
}

export async function setReportListPageNumberAction(pageNumber: number) {
    const state = reportListStore.getState();
    const updated = state
        .page
        .setPageNumber(pageNumber)
        .setList(List());
    dispatcher.dispatch(setReportListPage(updated));

    await fetchReportListAction();
}

export async function setReportListFilterAction(filter: ReportFilters) {
    dispatcher.dispatch(setReportListFilter(filter));
    cleanupReportDataAction();
    await fetchReportListAction();
}

export async function generateReportAction() {
    dispatcher.dispatch(generateReport());
    const state = reportListStore.getState()
    switch (state.filter.variety) {
        case ReportType.Project:
            await generateProjectReport(state.filter,catFileSelectActions.state.selectionModel);
            break;
        case ReportType.TranslatorStatistic:
            await generateTranslatorStatisticReport(state.filter);
            break;
        default:
            return
    }
}

export async function fetchReportListAction() {
    dispatcher.dispatch(setReportListIsLoading(true));
    await refreshReportListAction();
    dispatcher.dispatch(setReportListIsLoading(false))
}

export async function refreshReportListAction() {
    const state = reportListStore.getState()
    let page: Page<Report>;
    switch (state.filter.variety) {
        case ReportType.Project:
            page = await fetchProjectReport(state.filter, state.page.pageable);
            break;
        case ReportType.TranslatorStatistic:
            page = await fetchTranslatorStatisticReport(state.filter, state.page.pageable);
            break;
        default:
            return;
    }

    dispatcher.dispatch(setReportList(page, isGeneratingNew(page.list)));
}

function isGeneratingNew(report: List<Report>) {
    const isGeneratingNew = report
        .filter(value => value.state === ReportState.Generating)
        .size;

    return isGeneratingNew !== 0;
}