import {Record} from "immutable";

interface IYandexAccount {
    id: number,
    name: string,
    iamToken: string
}

const YandexAccountRecord = Record<IYandexAccount>({
    id: 0,
    name: "",
    iamToken: ""
})

export class YandexAccount extends YandexAccountRecord {
}