import {SegmentCell} from "../../../../globals/CommonComponents";
import {toggleSegmentSelectionAction} from "../../../../flux/segment/list/SegmentListActions";
import {Checkbox} from "@mui/material";

type SegmentSelectorProperties = {
    position: number,
    isSelected: boolean
}

export default function SegmentSelector(props: SegmentSelectorProperties) {
    return (
        <SegmentCell sx={{
            minWidth: '35px',
            width: '35px',
            position: 'relative',
            display: "flex",
            paddingLeft: '0 !important',
            alignItems: 'flex-start'
        }}>
            <Checkbox
                sx={{padding: 0}}
                id={`segment-selected-checkbox-${props.position}`}
                onChange={() => toggleSegmentSelectionAction(props.position)}
                checked={props.isSelected}/>
        </SegmentCell>
    )
}