import Immutable from "immutable";

export enum CatEventTypeEnum {
    CatFileCreatedEvent = "com.onecint.cat.projects.events.catfile.CatFileCreatedEvent",
    CatFileTranslationUploadedEvent = "com.onecint.cat.projects.events.catfile.CatFileTranslationUploadedEvent",
    CatFileMTEvent = "com.onecint.cat.projects.events.catfile.CatFileMTEvent",
    CatFilePretranslatedEvent = "com.onecint.cat.projects.events.catfile.CatFilePretranslatedEvent",
    CatFileDeletedEvent = "com.onecint.cat.projects.events.catfile.CatFileDeletedEvent",
    CatFileRolledBackEvent = "com.onecint.cat.projects.events.catfile.CatFileRolledBackEvent",
    CatFileUpdatedEvent = "com.onecint.cat.projects.events.catfile.CatFileUpdatedEvent",

    SegmentCreatedEvent = "com.onecint.cat.projects.events.segments.SegmentCreatedEvent",
    SegmentRecreatedEvent = "com.onecint.cat.projects.events.segments.SegmentRecreatedEvent",
    SegmentDeletedEvent = "com.onecint.cat.projects.events.segments.SegmentDeletedEvent",
    SegmentUpdatedEvent = "com.onecint.cat.projects.events.segments.SegmentUpdatedEvent",
    SegmentRolledBackEvent = "com.onecint.cat.projects.events.segments.SegmentRolledBackEvent",

    TranslationRolledBackEvent = "com.onecint.cat.projects.events.segments.TranslationRolledBackEvent",
    SegmentTranslationSavedEvent = "com.onecint.cat.projects.events.segments.SegmentTranslationSavedEvent",
    ClearTargetEvent = "com.onecint.cat.projects.events.segments.ClearTargetEvent",
    Deprecated_TranslationAssignee = "com.onecint.cat.projects.events.segments.SegmentTranslationAssigneeEvent",
    ConfirmedTranslationEvent = "com.onecint.cat.projects.events.segments.ConfirmedTranslationEvent",
    AddedDraftTranslationEvent = "com.onecint.cat.projects.events.segments.AddedDraftTranslationEvent",
    MTTranslationEvent = "com.onecint.cat.projects.events.segments.MTTranslationEvent",
    LLMTranslatedEvent = "com.onecint.cat.projects.events.segments.LLMTranslatedEvent",
    PretranslatedTranslationEvent = "com.onecint.cat.projects.events.segments.PretranslatedTranslationEvent",
    UploadedTranslationEvent = "com.onecint.cat.projects.events.segments.UploadedTranslationEvent",
    RevertedWorkflowStepEvent = "com.onecint.cat.projects.events.segments.RevertedWorkflowStepEvent",

    Undefined = "EventType.Undefined"
}

interface ICatEventTypeModel {
    id: string,
    type: CatEventTypeEnum
}

const CatEventTypeModelRecord = Immutable.Record<ICatEventTypeModel>({
    id: '',
    type: CatEventTypeEnum.Undefined,
});

export class CatEventTypeModel extends CatEventTypeModelRecord {
    static Undefined: CatEventTypeModel;

    static all() {
        const valueArray = Object
            .values(CatEventTypeEnum)
            .filter(v => v !== CatEventTypeEnum.Undefined)
            .map(v => this.fromType(v))

        return Immutable.List(valueArray);
    }

    static fromType(eventTypeEnum: CatEventTypeEnum) {
        return new CatEventTypeModel({
            type: eventTypeEnum,
            id: eventTypeEnum.toString()
        });
    }

    presentation() {
        switch (this.type) {
            case CatEventTypeEnum.CatFileTranslationUploadedEvent:
                return "Translations uploaded";
            case CatEventTypeEnum.CatFileMTEvent:
                return "Machine Translations processed";
            case CatEventTypeEnum.CatFilePretranslatedEvent:
                return "Pretranslated translations added";
            case CatEventTypeEnum.CatFileCreatedEvent:
                return "Files created";
            case CatEventTypeEnum.CatFileDeletedEvent:
                return "Files deleted"
            case CatEventTypeEnum.CatFileRolledBackEvent:
                return "Action reverted"
            case CatEventTypeEnum.CatFileUpdatedEvent:
                return "Files updated"
            case CatEventTypeEnum.SegmentCreatedEvent:
                return "Segment created"
            case CatEventTypeEnum.SegmentRecreatedEvent:
                return "Segment recreated"
            case CatEventTypeEnum.SegmentDeletedEvent:
                return "Segment deleted"
            case CatEventTypeEnum.SegmentUpdatedEvent:
                return "Segment updated"
            case CatEventTypeEnum.SegmentRolledBackEvent:
                return "Action reverted"
            case CatEventTypeEnum.TranslationRolledBackEvent:
                return "Translation reverted";
            case CatEventTypeEnum.Deprecated_TranslationAssignee:
            case CatEventTypeEnum.ConfirmedTranslationEvent:
                return "Translation confirmed"
            case CatEventTypeEnum.AddedDraftTranslationEvent:
                return "Draft translation added";
            case CatEventTypeEnum.UploadedTranslationEvent:
                return "Translations Uploaded";
            case CatEventTypeEnum.MTTranslationEvent:
                return "Machine Translations added";
            case CatEventTypeEnum.LLMTranslatedEvent:
                return "AI Translation added";
            case CatEventTypeEnum.PretranslatedTranslationEvent:
                return "Pretranslated translations added";
            case CatEventTypeEnum.SegmentTranslationSavedEvent:
                return "added translation(obsolete)";
            case CatEventTypeEnum.ClearTargetEvent:
                return "cleared target";
            case CatEventTypeEnum.RevertedWorkflowStepEvent:
                return "reverted workflow step"
            case CatEventTypeEnum.Undefined:
            default:
                return "undefined"
        }
    }
}