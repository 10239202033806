import {Record} from "immutable";
import {ReportType} from "./Report";
import dayjs from "dayjs";

export type ReportFilters = ProjectReportFilter | TranslatorStatisticReportFilter

interface IReportFilter {
    variety: ReportType
    isValid: boolean
}

interface ITranslatorStatisticReportFilter extends IReportFilter {
    variety: ReportType.TranslatorStatistic,
    projectId: number
    from: Date | null
    to: Date | null
}

const TranslatorStatisticFilterRecord = Record<ITranslatorStatisticReportFilter>({
    variety: ReportType.TranslatorStatistic,
    projectId: 0,
    isValid: true,
    from: dayjs().subtract(1, "month").startOf("month").toDate(),
    to: dayjs().subtract(1, "month").endOf("month").toDate()
});

export class TranslatorStatisticReportFilter extends TranslatorStatisticFilterRecord implements IReportFilter {
    public static ofProject(id: number) {
        return new TranslatorStatisticReportFilter({projectId: id});
    }
}

interface IProjectReportFilter extends IReportFilter {
    variety: ReportType.Project
    projectId: number,
}

const ProjectsReportFilterRecord = Record<IProjectReportFilter>({
    variety: ReportType.Project,
    isValid: true,
    projectId: 0,
});

export class ProjectReportFilter extends ProjectsReportFilterRecord implements IReportFilter {
    public static ofProject(id: number) {
        return new ProjectReportFilter({projectId: id});
    }
}
