import {Map, Record} from "immutable";
import {GridFilterItem, GridFilterModel, GridSortDirection, GridSortModel} from "@mui/x-data-grid";
import {IFilter} from "../../model/Filter";
import {addSearchParam, updateWithGridFilterModel, updateWithGridSortingModel} from "../../utils/ListViewUtils";

interface IUsersListOperatorFilter {
    nameOperator: string | null
}

interface IUsersListFilter extends IUsersListOperatorFilter {
    projectId: number,
    onlySubordinate: boolean,
    name: string | string[],
    userGroup: string | null,
    orderBy: string | null,
    orderDirection: string | null
}

const UsersListFilterRecord = Record<IUsersListFilter>({
    projectId: 0,
    onlySubordinate: true,
    name: [],
    nameOperator: null,
    userGroup: null,
    orderBy: null,
    orderDirection: null
});

export default class UsersListFilter extends UsersListFilterRecord implements IFilter<UsersListFilter> {
    updateWithGridFilterModel(model: GridFilterModel) {
        return updateWithGridFilterModel<IUsersListFilter, UsersListFilter>(this, model, AvailableUserFilters);
    }

    updateWithGridSortingModel(model: GridSortModel) {
        return updateWithGridSortingModel<IUsersListFilter, UsersListFilter>(this, model, AvailableUserSortedFields);
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "name", this.name);
        addSearchParam(result, "nameOperator", this.nameOperator);
        addSearchParam(result, "orderBy", this.orderBy);
        addSearchParam(result, "orderDirection", this.orderDirection);
        addSearchParam(result, "userGroup", this.userGroup);
        return result.join('&');
    }

    toGridFilterModel(): GridFilterModel {
        const items: GridFilterItem[] = [];
        if (this.name && this.nameOperator)
            items.push({field: 'name', operator: this.nameOperator, value: this.name});
        return {items: items};
    }

    toGridSortModel(): GridSortModel {
        if (this.orderBy && this.orderDirection) {
            const foundEntry = AvailableUserSortedFields.findEntry(value =>
                value === this.orderBy);
            if (foundEntry === undefined)
                return [];
            return [{field: foundEntry[0], sort: this.orderDirection as GridSortDirection}];
        }
        return [];
    }

    static fromSearchParams(projectId: number, searchParams: URLSearchParams) {
        return new UsersListFilter({
            projectId: projectId,
            name: searchParams.getAll("name").map(name => decodeURIComponent(name)),
            nameOperator: searchParams.get("nameOperator"),
            userGroup: searchParams.get("userGroup"),
            orderBy: searchParams.get("orderBy"),
            orderDirection: searchParams.get("orderDirection")
        });
    }
}

const AvailableUserFilters = Map<string, string>().set('name', 'nameOperator');

const AvailableUserSortedFields = Map<string, string>().set('name', 'name');