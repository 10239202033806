import axios from "axios";
import {buildUrl, dispatchError} from "../globals/ApiGlobals";

export function executeLLMTranslate(projectId: number, accountId: number, promptId: number) {
    const url = buildUrl(endpoint + "/yandex", {}, {
        projectId: projectId,
        accountId: accountId,
        promptId: promptId
    })

    axios
        .post(url, {})
        .catch(dispatchError);
}

const endpoint = "/api/projects/llm-translate";