import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {OverflowContainer} from "../../../globals/CommonComponents";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Immutable from "immutable";
import SegmentListFilter from "../../../flux/segment/list/SegmentListFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import Project from "../../../model/Project";
import SearchIcon from "@mui/icons-material/Search";
import segmentListStore, {SegmentListState} from "../../../flux/segment/list/SegmentListStore";
import {
    deleteSegmentTranslationsAction,
    lockTranslationsAction,
    setWorkflowAction,
    toggleClearTargetAlertAction
} from "../../../flux/segment/list/SegmentListActions";
import {ReactSetter} from "../../../globals/Types";
import usersListStore from "../../../flux/users/list/UsersListStore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {User} from "../../../model/User";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {segmentActivityActions} from "../../../flux/event/EventListActions";
import {SelectionModel} from "../../../model/SelectionModel";
import {grey} from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {AvailableResourceType} from "../../../model/AvailableResources";
import PrivateComponent from "../../PrivateComponent";
import {handleWorkflowFilterChanged, updatePage} from "../../../utils/FilterUtils";
import {catFileBuildActions} from "../../../flux/common/build/CatFileBuildActions";

const icon = <CheckBoxOutlineBlankIcon fontSize={"small"}/>
const checkedIcon = <CheckBoxIcon fontSize={"small"}/>

export default function FiltersRightFrame() {
    const projectState = projectPageStore.getState();
    const [currentProject, setCurrentProject] = useState<Project>(projectState.project);
    const initialFilterState = segmentListStore.getState().filter;
    const initialUsersState = usersListStore.getState();
    const [totalItems, setTotalItems] = useState(0);
    const [segmentListState, setSegmentListState] = useState(segmentListStore.getState());
    const [downloadMenuAnchor, setDownloadMenuAnchor]
        = useState<HTMLElement | null>(null);
    const downloadMenuOpen = Boolean(downloadMenuAnchor);

    const [selectedWorkflowSteps, setSelectedWorkflowSteps] = useState<Immutable.List<number>>(Immutable.List());
    const navigate = useNavigate();
    const [filter, setFilter] = useState(initialFilterState);
    const [workflowId, setWorkflowId] = useState(0);

    const [selection, setSelection] = useState(new SelectionModel());

    const [users, setUsers] = useState(initialUsersState.projectUsers?.list?.toArray() ?? []);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
        });
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const workflowIds = state.filter.workflowIds;
            setSegmentListState(state)
            setSelection(state.selection);
            setFilter(state.filter);
            setSelectedWorkflowSteps(workflowIds ? Immutable.List(workflowIds) : Immutable.List());
            setTotalItems(state.totalsItems);
        });
        const usersListListener = usersListStore.addListener(() => {
            const state = usersListStore.getState();
            setUsers(state.projectUsers?.list?.toArray() ?? [])
        });
        return () => {
            projectPageListener.remove();
            segmentListListener.remove();
            usersListListener.remove()
        }

    }, []);

    return (
        <OverflowContainer maxWidth="sm" sx={{height: '75%', maxHeight: '75%', margin: 0, maxWidth: '500px'}}>
            <Stack sx={{height: '100%', display: 'flex', maxWidth: '500px'}}>
                <Box
                    display="flex"
                    flexDirection="column"
                    maxHeight="149px"
                    pl={3}
                    pr={3}
                    borderBottom="1px solid #ccc"
                >
                    <Box>
                        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{width: '100%'}}>
                            <Typography
                                sx={{
                                    fontFamily: 'Source Sans Pro',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: '31.92px',
                                    letterSpacing: '1px',
                                }}
                            >ACTIONS</Typography>
                        </Stack>
                    </Box>
                    <Box mb={2}>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            <PrivateComponent resource={AvailableResourceType.SegmentsLock} writeAllow={true}>
                                {drawLockButton()}
                                {drawUnlockButton()}
                            </PrivateComponent>
                            <PrivateComponent resource={AvailableResourceType.ClearTarget}
                                              writeAllow={true}>
                                {drawClearTargetButton()}
                            </PrivateComponent>
                            <PrivateComponent resource={AvailableResourceType.CatFiles} readAllow={true}>
                                {drawDownloadButton(setDownloadMenuAnchor)}
                                <Menu open={downloadMenuOpen} onClose={() => setDownloadMenuAnchor(null)}
                                      anchorEl={downloadMenuAnchor}>
                                    <MenuItem
                                        onClick={() => handleSaveTranslationClicked(filter, setDownloadMenuAnchor)}>
                                        Download translations
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleSaveXliffClicked(filter, setDownloadMenuAnchor)}>
                                        Download xliff
                                    </MenuItem>
                                </Menu>
                            </PrivateComponent>
                        </Stack>
                    </Box>
                    <Box mb={2}>
                        {drawChangeStatusActionFilter(workflowId, setWorkflowId)}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="70vh"
                    gap='10px'
                    pl={3}
                    pr={3}
                >
                    <Box>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            <Typography sx={{
                                fontFamily: 'Source Sans Pro',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '31.92px',
                                letterSpacing: '1px',
                            }}>FILTERS</Typography>
                        </Stack>
                    </Box>
                    <Box mb={2}>
                        {drawSegmentContentSearch(filter, navigate, setFilter)}
                    </Box>
                    <Box mb={2}>
                        {drawWorkflowDropdown(selectedWorkflowSteps, filter, navigate, currentProject)}
                    </Box>
                    <Box mb={2}>
                        {drawCommentsDropdown(filter, navigate)}
                    </Box>
                    <Box mb={2}>
                        {drawLocksDropdown(filter, navigate)}
                    </Box>
                    <Box mb={2}>
                        {drawAuthorDropdown(filter, navigate, users)}
                    </Box>
                    <Box>
                        {drawDatePicker(filter, navigate)}
                    </Box>
                </Box>
            </Stack>
            {
                clearTargetAlert(selection, totalItems, segmentListState)
            }
        </OverflowContainer>
    )
}

function handleSearchInSourceIdFilterClickedAway(filter: SegmentListFilter, navigate: NavigateFunction) {
    updatePage(navigate, filter);
}

function handleSearchInSourceIdFilterChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, filter: SegmentListFilter, setFilter: (value: SegmentListFilter) => void) {
    const updatedFilter = filter.set("sourceId", event.target.value);
    setFilter(updatedFilter);
}

function handleKeyDownOnSearchInSourceIdFilter(event: React.KeyboardEvent<HTMLDivElement>, filter: SegmentListFilter, navigate: NavigateFunction) {
    event.stopPropagation();
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            updatePage(navigate, filter);
        }
    }
}

function drawChangeStatusActionFilter(workflowId: number, setWorkflowId: ReactSetter<number>) {
    return (
        <Stack direction={"row"} spacing={2}>
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <FormControl fullWidth>
                    <InputLabel id="select-workflow-to-change-label" size={'normal'}>Change Status</InputLabel>
                    <Select
                        labelId={"select-workflow-to-change-label"}
                        id="select-workflow-to-change"
                        value={workflowId}
                        label={"Change Status"}
                        onChange={(event) => handleModifySelectedAction(event, setWorkflowId)}>
                        {drawWorkflowMenuItems()}
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    )
}

async function handleModifySelectedAction(event: SelectChangeEvent<number>, setWorkflowId: any) {
    setWorkflowId(event.target.value as number);
    await setWorkflowAction(event.target.value as number)
    setWorkflowId(0);
}

function drawSegmentContentSearch(filter: SegmentListFilter,
                                  navigate: NavigateFunction,
                                  setFilter: any) {
    return (<Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <TextField size={"small"}
                           fullWidth
                           placeholder={"Segment ID"}
                           value={filter.sourceId ? filter.sourceId : ""}
                           onBlur={() => handleSearchInSourceIdFilterClickedAway(filter, navigate)}
                           onChange={e =>
                               handleSearchInSourceIdFilterChanged(e, filter, setFilter)}
                           onKeyDown={e =>
                               handleKeyDownOnSearchInSourceIdFilter(e, filter, navigate)}
                           slotProps={{
                               input: {
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <SearchIcon sx={{color: grey[500]}}/>
                                       </InputAdornment>
                                   ),
                                   style: {height: '40px'},
                               }
                           }}/>
            </Box>
        </Stack>
    )
        ;
}

function drawWorkflowMenuItems() {
    return projectPageStore
        .getState()
        .project
        .getWorkflowSteps()
        .map(step =>
            <MenuItem value={step.id} id={`workflow-select-item ${step.id}`}>
                {step.name}
            </MenuItem>
        )
}

function drawWorkflowDropdown(selectedWorkflowSteps: Immutable.List<number>, filter: SegmentListFilter, navigate: NavigateFunction, currentProject: Project) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink>
                        Workflow Stages
                    </InputLabel>
                    <Select sx={{height: '40px', lineHeight: '30px'}}
                            label="Workflow Stages"
                            displayEmpty
                            multiple value={selectedWorkflowSteps.toArray()}
                            onChange={e => handleWorkflowFilterChanged(e, filter, navigate)}
                            renderValue={(selected) => {
                                return selected.map(id => {
                                    if (!currentProject)
                                        return;
                                    const found = currentProject.workflow
                                        .find(step => step.id === Number(id));
                                    return !found ? id : found.name;
                                }).join(', ')
                            }} variant={'outlined'}>
                        {currentProject && currentProject.workflow.map(workflowStep =>
                            <MenuItem key={workflowStep.id} value={workflowStep.id}>
                                <ListItemText>{workflowStep.name}</ListItemText>
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    )
        ;
}

function drawCommentsDropdown(filter: SegmentListFilter,
                              navigate: NavigateFunction
) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel
                        shrink
                        sx={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                            textAlign: 'left',
                        }}>
                        Comments
                    </InputLabel>
                    <Select
                        value={getCommentsFilterState(filter)}
                        onChange={e => {
                            handleWithCommentsFilterChanged(
                                e,
                                filter,
                                navigate)
                        }
                        }
                        displayEmpty
                        label="Comments"
                        sx={{
                            height: '40px',
                            lineHeight: '40px',
                        }}>
                        <MenuItem value="0">Without comments</MenuItem>
                        <MenuItem value="1">With comments</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    );
}

function handleWithCommentsFilterChanged(event: SelectChangeEvent<string | number>, filter: SegmentListFilter, navigate: NavigateFunction) {
    const {
        target: {value}
    } = event;
    const isWithoutComments = value === '0'
    const updatedFilter = filter.setWithoutComments(isWithoutComments).setWithComments(!isWithoutComments);
    updatePage(navigate, updatedFilter);
}

function getLockFilterState(filter: SegmentListFilter) {
    if ((filter.withLocked === null || !filter.withLocked) && (filter.withUnlocked === null || !filter.withUnlocked))
        return "";
    return filter.withUnlocked ? 0 : 1;
}


function getCommentsFilterState(filter: SegmentListFilter) {
    if ((filter.withComments === null || !filter.withComments) && (filter.withoutComments === null || !filter.withoutComments))
        return "";
    return filter.withoutComments ? 0 : 1;
}

function drawLocksDropdown(filter: SegmentListFilter,
                           navigate: NavigateFunction
) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel
                        shrink
                        sx={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '12px',
                            letterSpacing: '0.15px',
                            textAlign: 'left',
                        }}>
                        Locks
                    </InputLabel>
                    <Select
                        value={getLockFilterState(filter)}
                        onChange={e => {
                            handleWithLockedFilterChanged(
                                e,
                                filter,
                                navigate)
                        }
                        }
                        displayEmpty
                        label="Locks"
                        sx={{
                            height: '40px',
                            lineHeight: '40px',
                        }}>
                        <MenuItem value="0">Unlocked Segments</MenuItem>
                        <MenuItem value="1">Locked Segments</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    )
        ;
}

function drawAuthorDropdown(filter: SegmentListFilter,
                            navigate: NavigateFunction,
                            users: User[]
) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                {<Autocomplete multiple size={"small"} fullWidth
                               options={[
                                   {name: 'Select All', action: 'selectAll'},
                                   {name: 'Clear All', action: 'clearAll'},
                                   ...users]}
                               disableCloseOnSelect
                               getOptionLabel={option => option.name}
                               renderOption={(props, option, {selected}) =>
                                   <li {...props}>
                                       <Checkbox icon={icon} checkedIcon={checkedIcon}
                                                 checked={selected}/>
                                       {option.name}
                                   </li>
                               }
                               renderInput={params =>
                                   <TextField
                                       {...params}
                                       label="Author"
                                       slotProps={{
                                           inputLabel: {
                                               shrink: true
                                           }
                                       }}
                                   />}
                               onChange={(_e, value) =>
                                   handleAuthorsFilterChanged(value, filter, navigate, users)}
                               value={users.filter(user =>
                                   filter.authors ? filter.authors.contains(user.id) : false)}/>}
            </Box>
        </Stack>
    );
}

function drawDatePicker(filter: SegmentListFilter,
                        navigate: NavigateFunction,
) {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            <Box sx={{flexGrow: 1, minWidth: '300px'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack>
                        <Stack direction={"row"} spacing={0.5}>
                            <DateTimePicker label={"Start date"}
                                            ampm={false}
                                            maxDate={filter.to != null ? dayjs(filter.to) : undefined}
                                            onChange={value => handleTimeFilterChanged(
                                                value,
                                                dayjs(filter.to),
                                                filter,
                                                navigate)}
                                            value={dayjs(filter.from)}
                                            sx={{
                                                minWidth: '126px', flex: 1,
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                    '& fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-error fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                },


                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                            color: '#6c757d',
                                                        },
                                                    },
                                                },
                                            }}
                            />
                            <Typography variant="body1"
                                        sx={{
                                            paddingRight: '10px',
                                            paddingLeft: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                            >
                                —
                            </Typography>
                            <DateTimePicker label={"End date"}
                                            ampm={false}
                                            minDate={filter.from != null ? dayjs(filter.from) : undefined}
                                            onChange={value => handleTimeFilterChanged(
                                                dayjs(filter.from),
                                                value,
                                                filter,
                                                navigate)}
                                            value={dayjs(filter.to)}
                                            sx={{
                                                minWidth: '126px', flex: 1,
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                    '& fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },
                                                    '&.Mui-error fieldset': {
                                                        borderColor: '#d0d0d0',
                                                    },

                                                },
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                        },
                                                    },
                                                    InputLabelProps: {
                                                        style: {
                                                            fontFamily: 'Roboto',
                                                            fontSize: '12px',
                                                            fontWeight: '400',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.15px',
                                                            textAlign: 'left',
                                                            color: '#6c757d',
                                                        },
                                                    },
                                                },
                                            }}
                            />
                        </Stack>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <Button size={"small"} onClick={() =>
                                handleTimeFilterChanged(null, null, filter, navigate)}>
                                Reset
                            </Button>
                        </Stack>
                    </Stack>
                </LocalizationProvider>
            </Box>
        </Stack>
    );
}

function handleAuthorsFilterChanged(value: any, filter: SegmentListFilter, navigate: NavigateFunction,
                                    users: User[]) {
    const containsSelectAll = value.some((item: { action: string; }) => item.action === 'selectAll');
    const containsClearAll = value.some((item: { action: string; }) => item.action === 'clearAll');
    const finalValueList = containsSelectAll ? users : containsClearAll ? [] : value;

    const updatedFilter = filter.set("authors", Immutable.List(finalValueList.map((user: { id: any; }) => user.id)));
    updatePage(navigate, updatedFilter);
}

function handleTimeFilterChanged(from: Dayjs | null,
                                 to: Dayjs | null,
                                 filter: SegmentListFilter,
                                 navigate: NavigateFunction) {
    const updatedFilter = filter
        .set("from", from && from.isValid() ? from.toDate() : null)
        .set("to", to && to.isValid() ? to.toDate() : null);
    updatePage(navigate, updatedFilter);
}

function handleWithLockedFilterChanged(event: SelectChangeEvent<string | number>, filter: SegmentListFilter, navigate: NavigateFunction) {
    const {
        target: {value}
    } = event;
    const isUnlocked = value === '0'
    const updatedFilter = filter.setWithUnlocked(isUnlocked).setWithLocked(!isUnlocked);
    updatePage(navigate, updatedFilter);
}

function clearTargetAlert(selection: SelectionModel, totalItems: number, segmentListState: SegmentListState) {
    const selectedSegmentsCounter = selection.countSelected(totalItems);
    let clearAlertTitle;
    const clearAlertTitleConst = "You are about to clear translation in the selected ";
    if (selectedSegmentsCounter == 0 && segmentListState.clearTargetAlertOpen)
        toggleClearTargetAlertAction();
    if (selection.isSelectAll)
        clearAlertTitle = "You are about to clear translations in all filtered rows. ";
    else if (selectedSegmentsCounter == 1)
        clearAlertTitle = clearAlertTitleConst + "row. ";
    else
        clearAlertTitle = clearAlertTitleConst + "rows. ";

    clearAlertTitle += "Please reconsider this action.";

    return (
        <Dialog
            open={segmentListState.clearTargetAlertOpen}
            aria-labelledby="clear-target-alert-dialog-title"
            aria-describedby="clear-target-alert-dialog-description">
            <DialogTitle id="clear-target-alert-dialog-title">
                {clearAlertTitle}
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => {
                    toggleClearTargetAlertAction();
                    deleteSegmentTranslationsAction(selection).then();
                    segmentActivityActions.refresh(true).then();
                }}>
                    Continue
                </Button>
                <Button onClick={() => toggleClearTargetAlertAction()} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function drawLockButton() {
    return (
        <Tooltip title="Lock">
            <IconButton onClick={() => lockTranslationsAction(true)}>
                {segmentListStore.getState().longTasks.isUpdatingSegments ? (
                    <CircularProgress size="20px"/>
                ) : (
                    <img src={"/Lock.svg"} alt="lock"/>
                )}
            </IconButton>
        </Tooltip>
    );
}


function drawUnlockButton() {
    return (
        <Tooltip title="Unlock">
            <IconButton onClick={() => lockTranslationsAction(false)}>
                {segmentListStore.getState().longTasks.isUpdatingSegments ? (
                    <CircularProgress size="20px"/>
                ) : (
                    <img src={"/Unlock.svg"} alt="unlock"/>
                )}
            </IconButton>
        </Tooltip>
    );
}

function drawClearTargetButton() {
    return (
        <Tooltip title="Clear Target">
            <IconButton onClick={toggleClearTargetAlertAction}>
                {segmentListStore.getState().longTasks.isUpdatingSegments ? (
                    <CircularProgress size="20px"/>
                ) : (
                    <img src={"/Clear.svg"} alt={"clear"}/>
                )}
            </IconButton>
        </Tooltip>
    );
}

function drawDownloadButton(setDownloadMenuAnchor: (value: (((prevState: (HTMLElement | null)) => (HTMLElement | null)) | HTMLElement | null)) => void) {
    return <Tooltip title="Download">
        <IconButton onClick={e => setDownloadMenuAnchor(e.currentTarget)}>
            <img src={"/FileDownloadFilled-Blue-1.svg"} alt={"download"}/>
        </IconButton>
    </Tooltip>;
}

const handleSaveXliffClicked = (filter: SegmentListFilter,
                                setDownloadMenuAnchor: (anchor: HTMLElement | null) => void) => {
    catFileBuildActions.build(filter, true);
    setDownloadMenuAnchor(null);
}

const handleSaveTranslationClicked = (filter: SegmentListFilter,
                                      setDownloadMenuAnchor: (anchor: HTMLElement | null) => void) => {
    catFileBuildActions.build(filter, false);
    setDownloadMenuAnchor(null);
}
