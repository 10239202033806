import React, {useState} from "react";
import {IconButton, Stack} from "@mui/material";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import ListView from "../common/ListView";
import AddIcon from "@mui/icons-material/Add";
import NullFilter from "../../flux/common/NullFilter";
import EditIcon from "@mui/icons-material/Edit";
import {ReactSetter} from "../../globals/Types";
import {getYandexAccountsPath} from "../../routes/admin/YandexAccountsRoute";
import {YandexAccount} from "../../model/YandexAccount";
import {YandexAccountEditor} from "./YandexAccountEditor";
import {yandexAccountListActions} from "../../flux/yandex-account/YandexAccountListActions";

export default function YandexAccountsList() {
    const [openEditor, setOpenEditor] = useState(false);
    const [account, setAccount] = useState<YandexAccount>(new YandexAccount());

    return (
        <Stack sx={{height: '100%'}}>
            <YandexAccountEditor open={openEditor} account={account} onClose={() => {
                setOpenEditor(false)
                setAccount(new YandexAccount());
            }}/>
            <Stack sx={{height: '100%'}}>
                <Stack direction={"row"}>
                    <IconButton onClick={() => setOpenEditor(true)}>
                        <AddIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={yandexAccountListActions}
                          columns={columns(setOpenEditor, setAccount)}
                          navigateProps={{navigator: getYandexAccountsPath}}
                          selection={false}
                          initialFilter={new NullFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(setOpenEditor: ReactSetter<boolean>, setAccount: ReactSetter<YandexAccount>): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            flex: 10,
            filterable: false,
            sortable: false,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            filterable: false,
            sortable: false,
            getActions: (params: GridRowParams<YandexAccount>) => [
                <GridActionsCellItem label={'Update'} icon={<EditIcon/>}
                                     onClick={() => {
                                         setAccount(params.row)
                                         setOpenEditor(true)
                                     }}/>
            ]
        }
    ];
}