import {Button, Chip, Divider, Stack, TextField} from "@mui/material";
import {YandexAccount} from "../../model/YandexAccount";
import {yandexAccountListActions} from "../../flux/yandex-account/YandexAccountListActions";
import React, {useState} from "react";
import {ReactSetter} from "../../globals/Types";
import {YandexLLMPrompt} from "../../model/YandexLLMPrompt";
import TextFieldSelect from "../common/TextFieldSelect";
import {yandexLLMPromptActions} from "../../flux/yandex-llm-prompt/YandexLLMPromptActions";
import Immutable from "immutable";
import {emptyAsNull} from "../../globals/ApiGlobals";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {executeLLMTranslate} from "../../api/LLMTranslateApi";
import {getCatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";

export function LLMTranslatePage() {
    const [prompt, setPrompt] = useState<YandexLLMPrompt>(new YandexLLMPrompt());
    const [account, setAccount] = useState<YandexAccount>(new YandexAccount());
    const navigate = useNavigate();

    return (
        <Stack sx={{height: '100%'}} direction={"column"}>
            <Stack direction={"row"} spacing={5}>
                <TextFieldSelect onSelect={selected => setAccount(selected)}
                                 label={"Account"}
                                 getPresentation={model => model.name}
                                 actions={yandexAccountListActions}>
                </TextFieldSelect>
                <TextFieldSelect onSelect={selected => setPrompt(selected)}
                                 label={"Prompt"}
                                 getPresentation={model => model.title}
                                 actions={yandexLLMPromptActions}>
                </TextFieldSelect>
            </Stack>
            <Divider sx={{marginY: 3}}>
                <Chip label="Prompt" size="medium"/>
            </Divider>
            <TextField
                margin={"normal"}
                id="llm-model-title"
                label="title"
                variant="standard"
                value={prompt.title}
                onChange={e => setPrompt(prompt.set("title", e.target.value))}
            />
            <TextField
                margin={"normal"}
                id="llm-model-uri"
                label="model uri"
                variant="standard"
                value={prompt.modelUri}
                onChange={e => setPrompt(prompt.set("modelUri", e.target.value))}
            />
            <TextField
                margin={"normal"}
                id="llm-prompt"
                label="text"
                value={prompt.text}
                variant="standard"
                multiline
                onChange={e => setPrompt(prompt.set("text", e.target.value))}
            />
            <Stack direction={"row"} spacing={5}>
                <TextField
                    margin={"normal"}
                    id="llm-temperature"
                    label="temperature"
                    value={prompt.temperature}
                    variant="standard"
                    type={"number"}
                    onBlur={() => {
                        let value = prompt.temperature;
                        if (value > 1)
                            value = 1;
                        if (value < 0.1)
                            value = 0.1;
                        setPrompt(prompt.set("temperature", value))
                    }}
                    onChange={e => {
                        setPrompt(prompt.set("temperature", parseFloat(e.target.value)))
                    }}
                />
                <TextField
                    margin={"normal"}
                    id="llm-max-tokens"
                    label="max tokens"
                    value={prompt.maxTokens}
                    variant="standard"
                    type={"number"}
                    onChange={e => {
                        setPrompt(prompt.set("maxTokens", parseFloat(e.target.value)))
                    }}
                />
                <TextField
                    margin={"normal"}
                    id="llm-system-role"
                    label="system role"
                    value={prompt.systemRole}
                    variant="standard"

                    onChange={e => setPrompt(prompt.set("systemRole", e.target.value))}
                />
                <TextField
                    margin={"normal"}
                    id="llm-user-role"
                    label="user role"
                    value={prompt.userRole}
                    variant="standard"

                    onChange={e => setPrompt(prompt.set("userRole", e.target.value))}
                />
            </Stack>
            <Stack direction={"row"} sx={{marginY: 3}} spacing={1}>

                <Button onClick={async () => {
                    await yandexLLMPromptActions.saveAll(Immutable.List.of(prompt));
                }}>
                    Save
                </Button>
                {drawCopyButton(prompt)}
                {drawDeleteButton(prompt, setPrompt)}
                {drawExecuteButton(prompt, account, navigate)}
            </Stack>
        </Stack>
    );
}

function drawExecuteButton(prompt: YandexLLMPrompt, account: YandexAccount, navigate: NavigateFunction) {
    if (emptyAsNull(account.id) == null)
        return null;

    if (emptyAsNull(prompt.id) == null)
        return null;

    const projectId = projectPageStore.getState().project.id

    return (
        <Button variant={"contained"} onClick={async () => {
            executeLLMTranslate(projectId, account.id, prompt.id);
            navigate(getCatFilesPath(new CatFilesFilter({projectId: projectId})));
        }}>
            Execute
        </Button>
    )
}


function drawCopyButton(prompt: YandexLLMPrompt) {
    if (emptyAsNull(prompt.id) == null)
        return null;

    return (
        <Button onClick={async () => {
            const copy = prompt.set("id", 0).set("title", prompt.title + "-copy")
            await yandexLLMPromptActions.saveAll(Immutable.List.of(copy))
        }}>
            Copy
        </Button>
    )
}

function drawDeleteButton(prompt: YandexLLMPrompt, setPrompt: ReactSetter<YandexLLMPrompt>) {
    if (emptyAsNull(prompt.id) == null)
        return null;

    return (
        <Button onClick={async () => {
            await yandexLLMPromptActions.delete(prompt)
            setPrompt(new YandexLLMPrompt());
        }}>
            Delete
        </Button>
    )
}

