import React, {useEffect, useRef, useState} from "react";
import {Segment} from "../../../../model/Segment";
import SegmentView from "./SegmentView";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {
    isSegmentPositionLoadedAction,
    loadMoreSegmentByPositionAction,
    pointToSegmentAction,
    resetSegmentListEditor,
    setLastClickedSegmentAction,
    toggleSegmentSelectionAction,
    toggleWithControlsAction
} from "../../../../flux/segment/list/SegmentListActions";
import {ListChildComponentProps, ListOnItemsRenderedProps, VariableSizeList} from "react-window";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {FontSizeCalc} from "../../../../globals/FontSizeCalc";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import Immutable from "immutable";
import {editorText} from "../../../../utils/slate/SlateUtils";
import {Box} from "@mui/material";

export default function SegmentList() {
    const listRef = useRef<VariableSizeList | null>(null);
    const [totalItems, setTotalItems] = useState(0);
    const [preloaded, setPreloaded] = useState(Immutable.Map<number, Segment>())
    const [position, setPosition] = useState(segmentListStore.getState().position);

    useEffect(() => {
        const list = listRef.current;
        if (!list)
            return;

        list.scrollToItem(position, 'smart');
    }, [position]);

    useEffect(() => {
        const list = listRef.current;
        if (!list)
            return;

        listRef.current!.resetAfterIndex(position, true);
    }, [preloaded]);

    useEffect(() => {
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setTotalItems(state.totalsItems);
            setPreloaded(state.items);
            setPosition(state.position);
        });

        return () => {
            segmentListListener.remove();
            resetSegmentListEditor();
        }
    }, []);

    return (
        <Box sx={{flex: 1}}>
            <AutoSizer>
                {(size: Size) =>
                    drawLazyLoadedSegments(size.height, size.width, totalItems, listRef)}
            </AutoSizer>
        </Box>
    );
}

function drawLazyLoadedSegments(height: number,
                                width: number,
                                totalItems: number,
                                listRef: React.MutableRefObject<VariableSizeList | null>) {
    return (
        <InfiniteLoader
            isItemLoaded={position => isSegmentPositionLoadedAction(position)}
            itemCount={totalItems}
            loadMoreItems={loadMoreSegmentByPositionAction}>
            {({onItemsRendered}) => {
                return drawSegmentsList(height, width, totalItems, onItemsRendered, listRef);
            }}
        </InfiniteLoader>)
}

function drawSegmentsList(
    height: number,
    width: number,
    itemCount: number,
    onItemsRendered: (props: ListOnItemsRenderedProps) => any,
    listRef: React.MutableRefObject<VariableSizeList | null>) {
    return (<VariableSizeList
        height={height}
        width={width}
        itemCount={itemCount}
        itemSize={(i: number) => {
            let segment = segmentListStore.getState().items.get(i);
            if (!segment)
                segment = Segment.Empty;

            // TODO: remove hardcode. Should be find out how to calc properly height
            const source = FontSizeCalc
                .Instance
                .calcHeight(
                    editorText(segment.sourceEditor),
                    (width - 300) / 2 - 50,
                    'Source Sans Pro,Arial,sans-serif',
                    '1rem',
                    'break-word');
            const sourceId = FontSizeCalc
                .Instance
                .calcHeight(
                    segment.sourceId,
                    (width - 300) / 2 - 50,
                    'Roboto', '12px',
                    'break-all');

            return Math.max(76, source + sourceId + 10);
        }}
        onItemsRendered={onItemsRendered}

        ref={listRef}>
        {({
              index,
              style
          }: ListChildComponentProps<Segment>) => drawSegment(index, style)}
    </VariableSizeList>)
}

function drawSegment(position: number, style: React.CSSProperties) {
    const state = segmentListStore.getState();
    let segment = state.items.get(position);
    if (!segment)
        segment = Segment.Empty;

    return (
        <SegmentView
            onClick={(event) => handleSegmentClicked(event, segment, position)}
            onShiftSegment={shift => pointToSegmentAction(position + shift)}
            segment={segment}
            position={position}
            key={`segment-${segment.id}`}
            style={style}
            isSelected={state.selection.isSelected(segment.id)}/>
    );
}

function handleSegmentClicked(event: React.MouseEvent, segment: Segment, position: number) {
    selectWithShiftKey(event, position);

    const inCurrent = segmentStore.getState().segment?.id === segment.id;
    if (inCurrent)
        return;

    pointToSegmentAction(position);
}

function selectWithShiftKey(event: React.MouseEvent, to: number) {
    if (!event.shiftKey) {
        setLastClickedSegmentAction(null);
        return;
    }

    const state = segmentListStore.getState();
    if (!state.withControls)
        toggleWithControlsAction();

    const from = state.lastClickedPosition;
    if (from === null || from === to) {
        toggleSegmentSelectionAction(to);
        setLastClickedSegmentAction(to);
        return;
    }

    const items = segmentListStore.getState().items;
    let toUpdate;

    if (from > to)
        toUpdate = items.filter((_ignored, position) => from > position && position >= to);
    else if (from < to)
        toUpdate = items.filter((_ignored, position) => to >= position && position > from);

    if (toUpdate)
        toUpdate.forEach((_ignored, position) => {
            toggleSegmentSelectionAction(position)
        });

    setLastClickedSegmentAction(to);
}