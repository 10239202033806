import {emptyAsNull} from "../../globals/ApiGlobals";
import {YandexLLMPrompt} from "../../model/YandexLLMPrompt";

export function toYandexLLMPromptModel(entity: IYandexPromptWebEntity | null) {
    if (entity == null)
        return new YandexLLMPrompt();

    return new YandexLLMPrompt({
        id: entity.id as number,
        title: entity.title,
        modelUri: entity.modelUri,
        text: entity.text,
        stream: entity.stream,
        temperature: entity.temperature,
        maxTokens: entity.maxTokens,
        systemRole: entity.systemRole,
        userRole: entity.userRole
    });
}

export function toYandexLLMPromptRequest(model: YandexLLMPrompt) {
    return {
        id: emptyAsNull(model.id),
        title: model.title,
        modelUri: model.modelUri,
        text: model.text,
        stream: model.stream,
        temperature: model.temperature,
        maxTokens: model.maxTokens,
        systemRole: model.systemRole,
        userRole: model.userRole
    } as IYandexPromptWebEntity;
}


export interface IYandexPromptWebEntity {
    id: number | null,
    title: string,
    modelUri: string,
    text: string,
    stream: boolean,
    temperature: number,
    maxTokens: number,
    systemRole: string,
    userRole: string
}