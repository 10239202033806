import PrivateComponent from "../../../components/PrivateComponent";
import {AvailableResourceType} from "../../../model/AvailableResources";
import React from "react";
import {LLMTranslatePage} from "../../../components/llm-translate/LLMTranslatePage";

export default function LLMTranslateRoute() {
    return (
        <PrivateComponent resource={AvailableResourceType.LLMTranslate} readAllow={true}>
            <LLMTranslatePage/>
        </PrivateComponent>
    );
}

export const LLMTranslatePath = "llm-translate";