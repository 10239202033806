import {CommonListActions} from "../common/list/CommonListActions";
import NullFilter from "../common/NullFilter";
import {AvailableResourceType} from "../../model/AvailableResources";
import {YandexLLMPrompt} from "../../model/YandexLLMPrompt";
import {deleteYandexLLMPrompts, fetchYandexLLMPrompts, saveYandexLLMPrompts} from "../../api/YandexLLMPromptApi";


export const yandexLLMPromptActions = new CommonListActions<YandexLLMPrompt, NullFilter>(
    (filter, page) => fetchYandexLLMPrompts(filter, page),
    deleteYandexLLMPrompts,
    saveYandexLLMPrompts,
    AvailableResourceType.Admin
);

