import {ArrowBackIos, Search} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import segmentListStore from "../../flux/segment/list/SegmentListStore";
import {InputAdornment, Link, Menu, MenuItem, SelectChangeEvent, TextField, Tooltip} from "@mui/material";
import {catFileListActions} from "../../flux/cat-files/CatFileListActions";
import {styled} from "@mui/material/styles";
import {Language} from "../../model/Language";
import {Page} from "../../model/Page";
import {CatFile} from "../../model/CatFile";
import SegmentListFilter from "../../flux/segment/list/SegmentListFilter";
import CustomPagination from "../common/CustomPagination";
import {LanguageList} from "../../model/LanguageList";
import {getSegmentPath} from "../../routes/EditorRoute";
import languageListStore from "../../flux/language/list/LanguageListStore";
import Typography from "@mui/material/Typography";

type CatFileDropdownProps = {
    isOpen: boolean
}
const MenuLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    },
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
});

const MenuLinkText = styled(Typography)({
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px'
});

const SearchField = styled(TextField)({
    padding: 1
});
export default function CatFileDropdown(props: CatFileDropdownProps) {
    const initialSegmentListFilterState = segmentListStore.getState();
    const initialLanguageListState = languageListStore.getState();
    const navigate = useNavigate();

    const [supportedLanguages, setSupportedLanguages]
        = useState(initialLanguageListState.languages);
    const [searchFileFilter, setSearchFileFilter] = useState("");
    const [filter, setFilter]
        = useState(initialSegmentListFilterState.filter);
    const [catFiles, setCatFiles] = useState(catFileListActions.state.page);
    const [catFile, setCatFile]
        = useState(initialSegmentListFilterState.filter.nonNullCatFile);

    const [selectFileMenuOpen, setSelectFileMenuOpen] = useState(false);
    const [selectFileMenuAnchor, setSelectFileMenuAnchor]
        = useState<HTMLElement | null>(null);

    useEffect(() => {
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setCatFile(state.filter.nonNullCatFile);
            setFilter(state.filter);
        });
        const catFilesListener = catFileListActions.store.addListener(() => {
            const state = catFileListActions.state;
            setCatFiles(state.page);
        });
        const languageListListener = languageListStore.addListener(() => {
            const state = languageListStore.getState();
            setSupportedLanguages(state.languages);
        });

        return () => {
            segmentListListener.remove();
            catFilesListener.remove();
            languageListListener.remove()
        }
    }, []);
    if (!props.isOpen)
        return null;

    return (
        <>
            <Tooltip title={catFile?.name}>
                <MenuLink variant="menu"
                          onClick={e => handleSelectFileMenuOpen(e, setSelectFileMenuAnchor, setSelectFileMenuOpen)}>
                    <MenuLinkText>{catFile?.name}</MenuLinkText>
                    <ArrowBackIos fontSize="inherit"
                                  sx={{
                                      marginLeft: '8px',
                                      marginBottom: '7px',
                                      transform: 'rotate(270deg)',
                                      alignItems: 'center',
                                      display: 'flex'
                                  }}/>
                </MenuLink>
            </Tooltip>
            <Menu open={selectFileMenuOpen} anchorEl={selectFileMenuAnchor}
                  onClose={() => handleSelectFileMenuClosed(
                      setSelectFileMenuAnchor,
                      setSelectFileMenuOpen,
                      setSearchFileFilter)}>
                {drawFileMenuItems(
                    getTargetLanguage(supportedLanguages, filter),
                    catFiles,
                    searchFileFilter,
                    filter,
                    setSelectFileMenuAnchor,
                    setSelectFileMenuOpen,
                    setSearchFileFilter,
                    navigate)}
            </Menu>
        </>
    );
}


function handleSelectFileMenuOpen(event: React.MouseEvent<HTMLElement>,
                                  setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                                  setSelectFileMenuOpen: (o: boolean) => void) {
    setSelectFileMenuAnchor(event.currentTarget);
    setSelectFileMenuOpen(true);
}

function handleSelectFileMenuClosed(setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                                    setSelectFileMenuOpen: (o: boolean) => void,
                                    setSearchFileFilter: (s: string) => void) {
    setSelectFileMenuAnchor(null);
    setSelectFileMenuOpen(false);
    setSearchFileFilter("");
}


function drawFileMenuItems(targetLanguage: Language | null,
                           catFiles: Page<CatFile>,
                           searchFileFilter: string,
                           filter: SegmentListFilter,
                           setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                           setSelectFileMenuOpen: (o: boolean) => void,
                           setSearchFileFilter: (s: string) => void,
                           navigate: NavigateFunction) {
    if (!targetLanguage)
        return;
    const menuItems = [<SearchField key={"file-menu-search"} size={"small"}
                                    placeholder={"Search..."}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position={"start"}>
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e =>
                                        handleSearchFileTextChanged(e, setSearchFileFilter)}
                                    onKeyDown={e => e.stopPropagation()}/>];
    catFiles.list.map(file => {
        if (!file.name.toLowerCase().includes(searchFileFilter))
            return;
        menuItems.push(<MenuItem key={"file-menu-" + file.id}
                                 onClick={() => handleSelectFile(
                                     file,
                                     filter,
                                     setSelectFileMenuAnchor,
                                     setSelectFileMenuOpen,
                                     setSearchFileFilter,
                                     navigate)}>
            {file.name}
        </MenuItem>);
    });
    menuItems.push(<CustomPagination pageable={catFiles.pageable} onChange={handleCatFilesPageChange}
                                     onRowsPerPageChange={handleCatFilesRowsPerPageChanged}
                                     key={"file-menu-pagination"}/>);
    return menuItems;
}

function getTargetLanguage(supportedLanguages: LanguageList, filter: SegmentListFilter) {
    const foundLanguage = supportedLanguages.findByCode(filter.nonNullLanguage);
    if (!foundLanguage)
        return null;
    return foundLanguage;
}

function handleSelectFile(catFile: CatFile,
                          filter: SegmentListFilter,
                          setSelectFileMenuAnchor: (e: HTMLElement | null) => void,
                          setSelectFileMenuOpen: (o: boolean) => void,
                          setSearchFileFilter: (s: string) => void,
                          navigate: NavigateFunction) {
    handleSelectFileMenuClosed(setSelectFileMenuAnchor, setSelectFileMenuOpen, setSearchFileFilter);
    navigate(getSegmentPath(filter.set("catFile", catFile), 0), {replace: true});
}

function handleSearchFileTextChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                     setSearchFileFilter: (s: string) => void) {
    setSearchFileFilter(event.target.value.toLowerCase());
}

function handleCatFilesRowsPerPageChanged(e: SelectChangeEvent<number>) {
    const catFilesState = catFileListActions.state;
    catFileListActions.fetchPageable(catFilesState.filter, catFilesState.page.setSize(Number(e.target.value)).pageable);
}

function handleCatFilesPageChange(page: number) {
    const catFilesState = catFileListActions.state;
    catFileListActions.fetchPageable(catFilesState.filter, catFilesState.page.setPageNumber(page).pageable);
}
