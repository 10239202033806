import React from "react";
import YandexAccountsList from "../../components/admin/YandexAccountsList";

export default function YandexAccountsRoute(){
    return (<YandexAccountsList/>);
}

export function getYandexAccountsPath() {
    return YandexAccountsPath;
}

export const YandexAccountsPath = "yandex-accounts";