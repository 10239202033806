import {Record} from "immutable";

interface IYandexLLMPrompt {
    id: number
    title: string,
    modelUri: string,
    text: string,
    stream: boolean,
    temperature: number,
    maxTokens: number,
    systemRole: string,
    userRole: string
}

const YandexLLMPromptRecord = Record<IYandexLLMPrompt>({
    id: 0,
    title: "",
    maxTokens: 1000,
    modelUri: "",
    stream: false,
    temperature: 0.1,
    text: "",
    systemRole: "system",
    userRole: "user"
})

export class YandexLLMPrompt extends YandexLLMPromptRecord {
}