import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchFetchListError, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {YandexAccount} from "../model/YandexAccount";
import NullFilter from "../flux/common/NullFilter";
import {Pageable} from "../model/Pageable";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Page} from "../model/Page";
import {List} from "immutable";
import {IYandexAccountResponse, toYandexAccountModel, toYandexAccountRequest} from "./model/YandexAccountApiModel";

export function fetchYandexAccounts(filter?: NullFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint,
        {},
        toParameters<NullFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IYandexAccountResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toYandexAccountModel(value));
            return Page
                .fromBackendPage<YandexAccount>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<YandexAccount>);
}

export function saveYandexAccounts(yandexAccounts: List<YandexAccount>) {
    return axios
        .post(endpoint, yandexAccounts.map(value => toYandexAccountRequest(value)).toJSON())
        .then((response: AxiosResponse<IYandexAccountResponse>) => {
            return List.of(toYandexAccountModel(response.data));
        })
        .catch(dispatchFetchListError<YandexAccount>);
}

const endpoint = "/api/mt/yandex-accounts";