import Dropzone from "react-dropzone";
import {Button, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import Immutable from "immutable";
import projectEditorStore from "../../../flux/project/editor/ProjectEditorStore";
import {drawFilesLoadingArea, DropzoneContentContainer, handleFilesDrop, MainContainer} from "./FilesLoadingArea";

export default function ProjectFilesTranslationsUploading() {
    const [files, setFiles]
        = useState<Immutable.List<File>>(Immutable.List<File>());

    useEffect(() => {
        const projectEditorListener = projectEditorStore.addListener(() => {
            const state = projectEditorStore.getState();
            setFiles(state.files);
        });
        return () => projectEditorListener.remove();
    });

    return (
        <Dropzone onDrop={acceptedFiles => handleFilesDrop(acceptedFiles, files)} noClick={true}
                  accept={{
                      'application/properties': ['.properties']
                  }}>
            {({getRootProps, getInputProps, open}) => {
                return (<div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()}/>
                    <MainContainer>
                        <DropzoneContentContainer>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                                {drawFilesLoadingArea(files)}
                                <Stack alignItems={"center"} justifyContent={"space-around"}
                                       spacing={2}>
                                    <Button variant={"contained"} onClick={open}>Upload files</Button>
                                </Stack>
                            </Stack>
                        </DropzoneContentContainer>
                    </MainContainer>
                </div>);
            }}
        </Dropzone>);
}