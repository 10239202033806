import {Button, Stack, Tabs} from "@mui/material";
import PageBreadcrumbs from "../PageBreadcrumbs";
import React, {useEffect, useState} from "react";
import {NavigateFunction, Outlet, useMatch, useNavigate, useOutletContext} from "react-router-dom";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {PretranslatePath} from "../../routes/project/PretranslateRoute";
import ProjectFilesEditor from "./editor/ProjectFilesEditor";
import {ProjectReportPath} from "../../routes/project/ProjectReportRoute";
import PrivateComponent from "../PrivateComponent";
import {AvailableResourceType} from "../../model/AvailableResources";
import {List} from "immutable";
import PrivateTab from "../PrivateTab";
import Project from "../../model/Project";
import {CatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import {ProjectTranslationMemoriesPath} from "../../routes/project/tabs/ProjectTranslationMemoriesRoute";
import {PermissionsPath} from "../../routes/project/tabs/permissions/PermissionsRoute";
import {ProjectPlaceholdersPath} from "../../routes/project/tabs/ProjectPlaceholdersRoute";
import {ProjectGlossariesPath} from "../../routes/project/tabs/ProjectGlossariesRoute";
import {ActivityPath} from "../../routes/project/tabs/ActivityRoute";
import {ProjectPath} from "../../routes/project/ProjectRoute";
import {selectedCatFilesBuildActions} from "../../flux/common/build/SelectedCatFilesBuildActions";
import ProjectFilesTranslationsEditor from "./editor/ProjectFilesTranslationsEditor";
import {LLMTranslatePath} from "../../routes/project/tabs/LLMTranslateRoute";

export type ProjectContextType = {
    project: Project
}

export default function ProjectView() {
    const initialProjectPageState = projectPageStore.getState();
    const navigate = useNavigate();
    const match = useMatch(ProjectPath + "/:childPath");
    let activeTab = 0;
    switch (match?.params.childPath) {
        case CatFilesPath:
            activeTab = 0;
            break;
        case ProjectPlaceholdersPath:
            activeTab = 1;
            break;
        case PermissionsPath:
            activeTab = 2;
            break;
        case ProjectTranslationMemoriesPath:
            activeTab = 3;
            break;
        case ProjectGlossariesPath:
            activeTab = 4;
            break;
        case ActivityPath:
            activeTab = 5;
            break;
        case LLMTranslatePath:
            activeTab = 6;
            break;
    }

    const [project, setProject] = useState(initialProjectPageState.project);
    const [build, setBuild] = useState(false);
    const [currentTab, setCurrentTab] = useState<number>(activeTab);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        });

        const projectBuildListener = selectedCatFilesBuildActions.addListener(() => {
            const state = selectedCatFilesBuildActions.state;
            const foundBuild = state.building.find(value => value.id === project.id);
            setBuild(foundBuild !== undefined);
        });

        return () => {
            projectPageListener.remove();
            projectBuildListener.remove();
        }
    }, []);

    return (
        <Stack sx={{height: '100%'}}>
            <ProjectFilesEditor/>
            <ProjectFilesTranslationsEditor/>
            <Stack spacing={2} sx={{height: '100%'}}>
                <PageBreadcrumbs/>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Tabs value={currentTab} onChange={(_e, value) =>
                        handleTabChanged(value, setCurrentTab, navigate)}>
                        <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.CatFiles)} label={"Files"}/>
                        <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.Placeholders)} label={"Placeholders"}/>
                        <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.Permissions)} label={"Permissions"}/>
                        <PrivateTab readAllow={true} writeAllow={true} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.TranslationMemories)} label={"TM"}/>
                        <PrivateTab readAllow={true} writeAllow={true} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.Glossaries)} label={"Glossaries"}/>
                        <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.Events)} label={"Activity"}/>
                        <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                                    resources={List.of(AvailableResourceType.LLMTranslate)} label={"LLM-translate"}/>
                    </Tabs>
                    <Stack direction={"row"} spacing={2}>
                        <PrivateComponent resource={AvailableResourceType.Pretranslate} writeAllow={true}>
                            <Button variant={"contained"}
                                    onClick={() => handlePretranslateClicked(project, navigate)}
                                    disabled={build}>
                                Pretranslate
                            </Button>
                        </PrivateComponent>
                        <PrivateComponent resource={AvailableResourceType.Report} readAllow={true} writeAllow={true}>
                            <Button variant={"contained"} onClick={() => handleReportClicked(project, navigate)}>
                                Report
                            </Button>
                        </PrivateComponent>
                    </Stack>
                </Stack>
                <Outlet context={{project} satisfies ProjectContextType}/>
            </Stack>
        </Stack>
    );
}

function handlePretranslateClicked(project: Project, navigate: NavigateFunction) {
    if (!project)
        return;
    navigate(PretranslatePath.replace(':id', project.id.toString()));
}

function handleReportClicked(project: Project, navigate: NavigateFunction) {
    if (!project)
        return;
    navigate(ProjectReportPath.replace(':id', project.id.toString()));
}

function handleTabChanged(newValue: number, setCurrentTab: (v: number) => void, navigate: NavigateFunction) {
    setCurrentTab(newValue);
    switch (newValue) {
        case 0:
            navigate(CatFilesPath);
            break;
        case 1:
            navigate(ProjectPlaceholdersPath);
            break;
        case 2:
            navigate(PermissionsPath);
            break;
        case 3:
            navigate(ProjectTranslationMemoriesPath);
            break;
        case 4:
            navigate(ProjectGlossariesPath);
            break;
        case 5:
            navigate(ActivityPath);
            break;
        case 6:
            navigate(LLMTranslatePath);
            break;
    }
}

export function useProject() {
    return useOutletContext<ProjectContextType>();
}