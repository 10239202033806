import {Button, Dialog, DialogTitle, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import Immutable from "immutable";
import {ReactSetter} from "../../globals/Types";
import {YandexAccount} from "../../model/YandexAccount";
import {yandexAccountListActions} from "../../flux/yandex-account/YandexAccountListActions";

interface IProps {
    account: YandexAccount,
    open: boolean,
    onClose: () => void
}

export function YandexAccountEditor(props: IProps) {
    const [name, setName] = useState(props.account.name);
    const [iamToken, setIamToken] = useState(props.account.iamToken);
    const [errorInName, setErrorInName] = useState(false)

    useEffect(() => {
        setName(props.account.name);
    }, [props.account]);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>Tenant</DialogTitle>
            <Stack direction={"column"} alignItems={"center"} spacing={2} padding={2}>
                <TextField fullWidth={true}
                           id="yandex-account-name"
                           label="Name"
                           variant="standard"
                           value={name}
                           error={errorInName}
                           helperText={errorInName ? "empty name" : ""}
                           onChange={(e) => {
                               setErrorInName(false);
                               setName(e.target.value);
                           }}/>

                <TextField fullWidth={true}
                           id="yandex-account-iam-token"
                           label="iam token"
                           variant="standard"
                           type="password"
                           value={iamToken}
                           onChange={(e) => {
                               setIamToken(e.target.value);
                           }}/>

                <Button fullWidth={true}
                        variant={"contained"}
                        onClick={async () => onSave(name, iamToken, setErrorInName, props)}>
                    Save
                </Button>
            </Stack>

        </Dialog>
    );
}

async function onSave(name: string, iamToken: string, setErrorInName: ReactSetter<boolean>, props: IProps) {
    if (name.trim() === '') {
        setErrorInName(true)
        return;
    }
    let updated = props.account.set("name", name);
    if (iamToken.trim() !== '')
        updated = updated.set("iamToken", iamToken);

    await yandexAccountListActions.saveAll(Immutable.List.of(updated));
    props.onClose();
}