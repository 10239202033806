import React, {useState} from "react";
import {ResponsiveTabs, SimpleTab} from "../../../globals/CommonComponents";
import ExistingTranslationsView from "./existing-translations/ExistingTranslationsView";
import {Container, Stack} from "@mui/material";
import {Language} from "../../../model/Language";
import {styled} from "@mui/material/styles";

export enum ExistingTranslationsTabName {
    All = "All",
    TM = "TM",
    TB = "TB"
}

type TopRightFrameProperties = {
    targetLanguage: Language | null
}

const TabContainer = styled(Container)({
    margin:0,
    maxWidth: '500px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
});

export default function MiddleRightFrame(props: TopRightFrameProperties) {
    const [currentTab, setCurrentTab]
        = useState(ExistingTranslationsTabName.All);

    const handleTabChanged = (value: ExistingTranslationsTabName) => {
        setCurrentTab(value);
    }

    return (
        <TabContainer maxWidth="sm" disableGutters >
            <Stack sx={{height: '100%',padding:0,maxWidth: '500px'}}>
                <Container>
                    <ResponsiveTabs value={currentTab} onChange={(_event, value) =>
                        handleTabChanged(value)}>
                        <SimpleTab label={"ALL"} value={ExistingTranslationsTabName.All}/>
                        <SimpleTab label={"TM"} value={ExistingTranslationsTabName.TM}/>
                        <SimpleTab label={"TB"} value={ExistingTranslationsTabName.TB}/>
                    </ResponsiveTabs>
                </Container>
                <ExistingTranslationsView currentTab={currentTab} targetLanguage={props.targetLanguage}/>
            </Stack>
        </TabContainer>
    );
}
