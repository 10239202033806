import {List, Record} from "immutable";
import {IReportData} from "./ReportDataType";

interface ITranslatorStatisticReportDimension {
    catFileId: number
    catFileName: string,
    workflowStepId: number,
    workflowStep: string,
    languageCode: string,
    username: string
}

interface ITranslatorStatisticData extends IReportData {
    dimension: ITranslatorStatisticReportDimension,
}

const ReportDataRecord = Record<ITranslatorStatisticData>({
    dimension: {
        catFileId: 0,
        catFileName: "",
        languageCode: "",
        username: "",
        workflowStepId: 0,
        workflowStep: "",
    },
    columns: List(),
    rows: List(),
});

export class TranslatorStatisticReportData extends ReportDataRecord {
}

