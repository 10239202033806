import React, {useState} from "react";
import {Stack, Tab, Tabs} from "@mui/material";
import PageBreadcrumbs from "../PageBreadcrumbs";
import {TenantsPath} from "../../routes/admin/TenantsRoute";
import {NavigateFunction, Outlet, PathMatch, useMatch, useNavigate} from "react-router-dom";
import {AdminPanePath} from "../../routes/admin/AdminPaneRoute";
import {AdminUsersPath} from "../../routes/admin/AdminUsersRoute";
import {YandexAccountsPath} from "../../routes/admin/YandexAccountsRoute";

export default function AdminPane() {
    const navigate = useNavigate();
    const pathMatch = useMatch(AdminPanePath + "/:childPath");
    const [currentTab, setCurrentTab] = useState<number>(getActiveTab(pathMatch));

    return (
        <Stack direction={"column"}>
            <PageBreadcrumbs/>
            <Tabs value={currentTab} onChange={(_e, value) => handleTabChanged(value, setCurrentTab, navigate)}>
                <Tab label={"Users"}/>
                <Tab label={"Tenants"}/>
                <Tab label={"Yandex Accounts"}/>
            </Tabs>
            <Outlet/>
        </Stack>
    );
}

function getActiveTab(match: PathMatch | null) {
    let activeTab = 0;
    switch (match?.params.childPath) {
        case AdminUsersPath:
            activeTab = 0;
            break;
        case TenantsPath:
            activeTab = 1;
            break;
        case YandexAccountsPath:
            activeTab = 2;
            break;
    }

    return activeTab;
}

function handleTabChanged(newValue: number, setCurrentTab: (v: number) => void, navigate: NavigateFunction) {
    setCurrentTab(newValue);
    switch (newValue) {
        case 0:
            navigate(AdminUsersPath);
            break;
        case 1:
            navigate(TenantsPath);
            break;
        case 2:
            navigate(YandexAccountsPath);
            break;
    }
}