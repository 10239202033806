import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchListError,
    dispatchFetchPageError,
    toParameters
} from "../globals/ApiGlobals";
import {YandexLLMPrompt} from "../model/YandexLLMPrompt";
import NullFilter from "../flux/common/NullFilter";
import {Pageable} from "../model/Pageable";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Page} from "../model/Page";
import {List, Set} from "immutable";
import {IYandexPromptWebEntity, toYandexLLMPromptModel, toYandexLLMPromptRequest} from "./model/YandexPromptApiModel";

export function fetchYandexLLMPrompts(filter?: NullFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint,
        {},
        toParameters<NullFilter>(filter, page));

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IYandexPromptWebEntity[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toYandexLLMPromptModel(value));
            return Page
                .fromBackendPage<YandexLLMPrompt>(response.data.page)
                .setList(List(list))
        })
        .catch(dispatchFetchPageError<YandexLLMPrompt>);
}

export function saveYandexLLMPrompts(prompts: List<YandexLLMPrompt>) {
    return axios
        .post(endpoint, prompts.map(value => toYandexLLMPromptRequest(value)).toJSON())
        .then((response: AxiosResponse<IYandexPromptWebEntity>) => {
            return List.of(toYandexLLMPromptModel(response.data));
        })
        .catch(dispatchFetchListError<YandexLLMPrompt>);
}

export function deleteYandexLLMPrompts(prompts: Set<YandexLLMPrompt>) {
    return Promise
        .all(prompts.map(value => deleteYandexLLMPrompt(value.id)))
        .then(() => {
        });
}

export function deleteYandexLLMPrompt(id: number) {
    axios
        .delete(endpoint + "/" + id)
        .catch(dispatchError);
}

const endpoint = "/api/mt/yandex-llm-prompt";