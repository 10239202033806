import {CommonListActions} from "../common/list/CommonListActions";
import NullFilter from "../common/NullFilter";
import {dispatchUnsupportedOperation} from "../../globals/ApiGlobals";
import {AvailableResourceType} from "../../model/AvailableResources";
import {fetchYandexAccounts, saveYandexAccounts} from "../../api/YandexAccountApi";
import {YandexAccount} from "../../model/YandexAccount";

export const yandexAccountListActions = new CommonListActions<YandexAccount, NullFilter>(
    (filter, page) => fetchYandexAccounts(filter,page),
    async () => {
        dispatchUnsupportedOperation();
        return;
    },
    saveYandexAccounts,
    AvailableResourceType.Admin
);

